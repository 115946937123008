import React, { useState } from 'react'
import classNames from 'classnames'
import { parse } from 'query-string'

import './App.css'
import './font/iconfont.css'

import wave from './img/wave.png'
import bg from './img/bg.svg'
import ztc from './img/ztc.jpeg'
import { useInterval } from './utils'
import request from './request'

enum loginType {
  code = 3,
  pass = 0
}

type loginModel = {
  userName?: string,
  password?: string,
  code?: string,
  rememberLogin: boolean,
  loginType: number,
  returnUrl: string,
}

let timer: NodeJS.Timeout
let loading = false

function App() {

  const { location } = window;
  const queryFromUrl = React.useMemo(() => {
    return parse(location.search);
  }, [location.search]);

  const [focus, setFocus] = useState('')
  const [showPassword, setShowPassword] = React.useState(false);
  const [form, setForm] = useState<loginModel>({ userName: '', password: '', code: '', rememberLogin: true, loginType: loginType.code, returnUrl: '' })
  const [message, setMessage] = React.useState<string>()
  const [second, setSecond] = React.useState(59);
  const [isRunning, checkRunning] = React.useState(false)
  useInterval(
    () => {
      setSecond(second - 1);
      if (second <= 0) {
        checkRunning(false);
        setSecond(59);
      }
    },
    isRunning ? 1000 : null,
  )

  const showMessage = (message: string) => {
    clearTimeout(timer)
    setMessage(message)
    timer = setTimeout(hideMessage, 1000 * 3)
  }

  const hideMessage = () => {
    clearTimeout(timer)
    setMessage('')
  }


  const handleType = (type: loginType) => {
    setMessage('')
    setForm({ userName: '', password: '', code: '', rememberLogin: true, loginType: type, returnUrl: '' })
  }

  const handleInput = (value: string, name: string) => {
    setForm(pre => { return { ...pre, [name]: value } })
  }

  const handleSendCode = () => {
    if (isRunning) {
      return
    }
    if (!form.userName) {
      showMessage('请输入手机号')
      return
    }
    if (!/^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/.test(form.userName)) {
      showMessage('请输入正确的手机号')
      return
    }
    request({
      path: `https://fzzt.fzgldc.com:28017/sso/api/account/${form.userName}`
    }).then(data => {
      checkRunning(data)
    }).catch((e: Error) => {
      showMessage(e.message)
    })
  }

  const handleSubmit = () => {
    if (loading) {
      return
    }

    if (form.loginType === loginType.code && !form.userName) {
      showMessage('请输入手机号')
      return
    }
    if (form.loginType === loginType.code && !form.code) {
      showMessage('请输入验证码')
      return
    }

    if (form.loginType === loginType.pass && !form.userName) {
      showMessage('请输入用户名')
      return
    }
    if (form.loginType === loginType.pass && !form.password) {
      showMessage('请输入密码')
      return
    }

    loading = true

    const loginModel = {
      ...form,
      returnUrl: queryFromUrl.returnUrl,
    };
    const origin = `${window.location.protocol}//${window.location.host}`;
    request({
      path: `${origin}/api/extend-login/v1/account/login`,
      data: loginModel,
    }).then(data => {
      if (!data.succeeded) {
        showMessage(data.error)
      } else if (data.returnUrl) {
        window.location.href = data.returnUrl
      } else {
        showMessage('跳转地址错误')
      }
      loading = false
    }).catch((e: Error) => {
      showMessage(e.message)
      loading = false
    })
  }
  return (
    <>
      <img alt='' className='wave' src={wave} />
      <div className='container'>
        <div className='img'>
          <img alt='' src={bg} />
        </div>
        <div className='login-content'>
          <div className={classNames('card-3d-wrapper', { 'card-change': form.loginType === loginType.code })}>
            <div className='card card-front'>
              <img alt='' src={ztc} />
              <h2 className='title'>欢迎登录</h2>
              <div className={classNames('input-div one', {
                'focus': focus === 'phone' || form.userName
              })}>
                <div className='i'>
                  <i className='iconfont icon-phone'></i>
                </div>
                <div className='div'>
                  <h5>手机号</h5>
                  <input type='text' className='input' value={form.userName} onChange={(e) => handleInput(e.target.value, 'userName')} onFocus={() => setFocus('phone')} onBlur={() => setFocus('')} />
                </div>
              </div>
              <div className={classNames('input-div code', {
                'focus': focus === 'code' || form.code
              })}>
                <div className='i'>
                  <i className='iconfont icon-lock'></i>
                </div>
                <div className='div'>
                  <h5>验证码</h5>
                  <input type='text' className='input' value={form.code} onChange={(e) => handleInput(e.target.value, 'code')} onFocus={() => setFocus('code')} onBlur={() => setFocus('')} />
                </div>
                <div onClick={handleSendCode}>
                  <span>{isRunning ? `${second}秒后再试` : '获取验证码'}</span>
                </div>
              </div>
              <div className='bottom-div'>
                <span className='left' onClick={() => handleType(loginType.pass)}>用户名登录</span>
              </div>
              <div onClick={handleSubmit} className='btn' >登  录</div>
              {message && <div className='message'>{message}</div>}
            </div>
            <div className='card card-back'>
              <img alt='' src={ztc} />
              <h2 className='title'>欢迎登录</h2>
              <div className={classNames('input-div one', {
                'focus': focus === 'user' || form.userName
              })}>
                <div className='i'>
                  <i className='iconfont icon-people'></i>
                </div>
                <div className='div'>
                  <h5>用户名</h5>
                  <input type='text' className='input' value={form.userName} onChange={(e) => handleInput(e.target.value, 'userName')} onFocus={() => setFocus('user')} onBlur={() => setFocus('')} />
                </div>
              </div>
              <div className={classNames('input-div pass', {
                'focus': focus === 'pass' || form.password
              })}>
                <div className='i'>
                  <i className='iconfont icon-lock'></i>
                </div>
                <div className='div'>
                  <h5>密  码</h5>
                  <input type={showPassword ? 'text' : 'password'} className='input' value={form.password} onChange={(e) => handleInput(e.target.value, 'password')} onFocus={() => setFocus('pass')} onBlur={() => setFocus('')} />
                </div>
                <div className='i' onClick={() => setShowPassword(!showPassword)}>
                  <i style={{ color: '#d9d9d9' }} className={classNames('iconfont', { 'icon-attentionforbid': !showPassword, 'icon-attention': showPassword })}></i>
                </div>
              </div>
              <div className='bottom-div'>
                <span className='left' onClick={() => handleType(loginType.code)} >手机号登录</span>
                <a href={`reset?returnUrl=${encodeURIComponent(location.href)}`} className='right'>忘记密码?</a>
              </div>
              <div onClick={handleSubmit} className='btn' >登  录</div>
              {message && <div className='message'>{message}</div>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
